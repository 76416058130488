<template>
  <b-modal
    v-model="isShowModal"
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    :title="title"
  >
    <h5 class="py-2 px-4">{{ content }}</h5>
    <template #modal-footer="{}">
      <b-button
        class="text-white px-5"
        variant="danger"
        @click="closeModal('no')"
      >
        No
      </b-button>
      <b-button
        class="text-white px-5"
        variant="success"
        @click="closeModal('yes')"
      >
        Yes
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'confirmModal',
  props: ['title', 'content'],
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },
    closeModal(status) {
      this.$emit('handleCloseModal', status);
      this.isShowModal = false;
    },
  },
};
</script>
